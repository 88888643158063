import type { ElementType } from 'react';
import type { LinkProps, ThemeOptions } from '@mui/material';
import { coreThemeConstants } from '@noah-labs/fe-shared-ui-components';
import { palette } from './palette';
import { typography } from './typography';

const { primary } = palette?.websiteBrand || {};
const { black, darkGrey, grey, white } = palette?.websiteBrand?.greyscale || {};

export function components(
  Link: ElementType | undefined,
  borderRadius: ThemeOptions['borderRadius'],
): ThemeOptions['components'] {
  return {
    MuiButton: {
      styleOverrides: {
        // overriding default MUI styles for startIcon and endIcon to match with expected SvgIcon sizing
        iconSizeMedium: {
          '& > *:nth-of-type(1)': {
            fontSize: '1.5rem',
          },
        },
        root: {
          borderRadius: borderRadius.cylinder,
          width: 'fit-content',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: Link, // See: https://next.material-ui.com/guides/routing/#global-theme-link
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        color: 'primary',
        variant: 'outlined',
      },
      styleOverrides: {
        colorSecondary: {
          borderColor: darkGrey,
          color: darkGrey,
        },
        icon: {
          fontSize: typography.button?.fontSize,
          marginLeft: coreThemeConstants.spacing(1.5),
        },
        root: {
          borderWidth: '2px',
          height: 'fit-content',
          padding: coreThemeConstants.spacing(0.5, 0),
          ...typography.button,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: coreThemeConstants.spacing(4),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: darkGrey,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ...typography.paragraphBodyM,
          '&:-webkit-autofill, &:-internal-autofill-selected': {
            background: 'inherit',
            caretColor: 'auto',
            color: 'inherit',
            WebkitBoxShadow: 'none',
            WebkitTextFillColor: 'inherit',
          },
          // Needs height auto as MUI is setting a fixed height
          height: 'auto',
        },
        multiline: {
          '.MuiOutlinedInput-input': {
            borderRadius: borderRadius.md,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: borderRadius.md,
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        // Setting shrink to 'true' enables the placeholders
        shrink: true,
      },
      styleOverrides: {
        outlined: {
          marginBottom: '2px',
          position: 'initial',
          transform: 'none',
        },
        root: {
          '&.Mui-focused': {
            color: palette?.text?.primary,
          },
          ...typography.paragraphBodyS,
          paddingLeft: coreThemeConstants.spacing(2),
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: Link, // See: https://next.material-ui.com/guides/routing/#global-theme-link
      } as LinkProps,
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        input: {
          borderRadius: borderRadius.cylinder,
          padding: coreThemeConstants.spacing(1.75, 0),
        },
        notchedOutline: {
          borderColor: grey,
          borderRadius: borderRadius.cylinder,
        },
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },
          '&.MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline, :hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: darkGrey,
            },
          '&.MuiInputBase-multiline': {
            borderRadius: borderRadius.md,
          },
          backgroundColor: white,
          borderRadius: borderRadius.cylinder,
          padding: coreThemeConstants.spacing(0, 2),
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: black,
          height: '1px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            borderWidth: 0,
          },
          borderWidth: 0,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          ':not(:first-of-type),:not(:last-of-type)': {
            borderLeft: 'none',
            borderRadius: borderRadius.md,
            margin: 0,
          },
          padding: coreThemeConstants.spacing(0.5),
        },
        root: {
          padding: coreThemeConstants.spacing(0.25, 0.5),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: white,
          borderColor: primary,
          borderStyle: 'solid',
          borderWidth: '1px',
          color: black,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          navigationItem: 'span',
        },
      },
    },
  };
}

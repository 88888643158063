import type { ElementType } from 'react';
import { css } from '@emotion/react';
import type { Theme } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import * as expanded from '@noah-labs/fe-feature-assets/fonts/gt-america-expanded';
import * as extended from '@noah-labs/fe-feature-assets/fonts/gt-america-extended';
import * as standard from '@noah-labs/fe-feature-assets/fonts/gt-america-standard';
import { coreThemeProps, cssMixins } from '@noah-labs/fe-shared-ui-components';
import { baseThemeProps } from './baseTheme';
import { components } from './components';
import { palette } from './palette';
import { typography } from './typography';

export function theme(Link?: ElementType): Theme {
  const customTheme = deepmerge(coreThemeProps, {
    ...baseThemeProps,
    components: components(Link, baseThemeProps.borderRadius),
    palette,
    typography,
  });
  const baseTheme = createTheme(customTheme);
  return responsiveFontSizes(baseTheme);
}

// TODO: can be updated with `${expanded.normal500};` if we get the 500 weight font
export const globalStyles = css`
  ${cssMixins.global};
  ${standard.italic400};
  ${standard.normal200}
  ${standard.normal400};
  ${standard.normal500};
  ${standard.normal700};
  ${extended.normal500};
  ${extended.normal900};
  ${expanded.normal900};
`;

import { forwardRef } from 'react';
import { isExternalLink } from '@noah-labs/fe-shared-ui-components';
import type { GatsbyLinkProps } from 'gatsby';
// eslint-disable-next-line no-restricted-imports
import { Link as GatsbyLink } from 'gatsby';

type PpLinkBehaviorGatsby = Omit<GatsbyLinkProps<Record<string, unknown>>, 'to'> & {
  children: React.ReactNode;
  href: string;
};

// See: https://next.material-ui.com/guides/routing/#global-theme-link
export const LinkBehaviorGatsby = forwardRef<HTMLAnchorElement, PpLinkBehaviorGatsby>(
  ({ children, href, ...rest }, ref) =>
    isExternalLink(href) ? (
      <a ref={ref} href={href} rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    ) : (
      // @ts-expect-error some awkward typescript error (false positive)
      <GatsbyLink ref={ref} to={href} {...rest}>
        {children}
      </GatsbyLink>
    ),
);

LinkBehaviorGatsby.displayName = LinkBehaviorGatsby.name;

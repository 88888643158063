export const websiteColors = {
  black: '#383838',
  darkGrey: '#717171',
  gradients: {
    tangerineTransparentGradient:
      'linear-gradient(90deg, rgba(255,255,255,0) 1%, rgba(255,107,0,1) 100%)',
  },
  grey: '#D8D8D8',
  indigo: '#5229FF',
  lighterGrey: '#F9F9F9',
  lightGrey: '#EAEAEA',
  mediumGrey: '#C4C4C4',
  plainBlack: '#000000',
  semiTransparentWhite: 'rgba(255,255,255,0.7)',
  tangerine: '#FF6B00',
  white: '#ffffff',
};

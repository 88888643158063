import type React from 'react';
import type { TypographyVariantsOptions } from '@mui/material/styles';
import { coreThemeConstants } from '@noah-labs/fe-shared-ui-components';
import { baseThemeProps } from './baseTheme';

const typographyBase: React.CSSProperties = {
  fontFamily: coreThemeConstants.fontFamily.standard,
  fontStyle: 'normal',
};

const typographyRegularBase: React.CSSProperties = {
  ...typographyBase,
  fontWeight: 400,
};

const typographyMediumBase: React.CSSProperties = {
  ...typographyBase,
  fontWeight: 500,
};

export const typography: TypographyVariantsOptions = {
  blogArticle: {
    ...typographyRegularBase,
    fontSize: '1.25rem',
    letterSpacing: '-0.01em',
    lineHeight: 1.6,
    [baseThemeProps.breakpoints.down('md')]: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
  bodyHeadingL: {
    ...typographyMediumBase,
    fontSize: '2rem',
    letterSpacing: '-0.03em',
    lineHeight: 1.25,
    [baseThemeProps.breakpoints.down('sm')]: {
      letterSpacing: ' -0.025em',
    },
  },
  bodyHeadingM: {
    ...typographyBase,
    fontSize: '2rem',
    fontWeight: 400,
    letterSpacing: '-0.02em',
    lineHeight: 1.12,
    [baseThemeProps.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
      fontWeight: 500,
      letterSpacing: '-0.03em',
      lineHeight: 1.14,
    },
  },
  bodyHeadingS: {
    ...typographyMediumBase,
    fontSize: '1.75rem',
    letterSpacing: '-0.04em',
    lineHeight: 1.14,
    [baseThemeProps.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      letterSpacing: '-0.03em',
      lineHeight: 1.12,
    },
  },
  bodyHeadingXS: {
    ...typographyBase,
    fontSize: '1.75rem',
    fontWeight: 400,
    letterSpacing: '-0.02em',
    lineHeight: 1.14,
    [baseThemeProps.breakpoints.down('lg')]: {
      fontSize: '1.188rem',
      fontWeight: 500,
      letterSpacing: '-0.03em',
      lineHeight: 1.42,
    },
  },
  button: {
    ...typographyRegularBase,
    fontSize: '0.875rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.14,
  },
  buttonL: {
    ...typographyMediumBase,
    fontSize: '0.875rem',
    letterSpacing: '-0.01em',
    lineHeight: 1.29,
  },
  buttonS: {
    ...typographyRegularBase,
    fontSize: '0.75rem',
    letterSpacing: '-0.05em',
    lineHeight: 1.33,
  },
  fontFamily: coreThemeConstants.fontFamily.standard,
  // These values just affect some defaults for MUI, not our mappings
  fontWeightBold: 900,
  fontWeightMedium: 700,
  fontWeightRegular: 400,
  footerHeadingL: {
    ...typographyMediumBase,
    fontSize: '1.063rem',
    letterSpacing: '-0.02em',
  },
  h1: {
    ...typographyMediumBase,
    fontSize: '7.5rem',
    letterSpacing: '-0.06em',
    lineHeight: 1.03,
    textTransform: 'none',
    [baseThemeProps.breakpoints.down('sm')]: {
      fontSize: '5rem',
      letterSpacing: '-0.05em',
      lineHeight: 1.05,
    },
  },
  h2: {
    ...typographyMediumBase,
    fontSize: '5.625rem',
    letterSpacing: '-0.025em',
    lineHeight: 1.07,
    textTransform: 'none',
    [baseThemeProps.breakpoints.down('sm')]: {
      fontSize: '3.5rem',
      letterSpacing: '-0.05em',
      lineHeight: 1.07,
    },
  },
  h3: {
    ...typographyMediumBase,
    fontSize: '5rem',
    letterSpacing: '-0.05em',
    lineHeight: 1.01,
    textTransform: 'none',
    [baseThemeProps.breakpoints.down('sm')]: {
      fontSize: '3rem',
      letterSpacing: '-0.03em',
      lineHeight: 1.17,
    },
  },
  h4: {
    ...typographyMediumBase,
    fontSize: '3.5rem',
    letterSpacing: '-0.05em',
    lineHeight: 1.07,
    textTransform: 'none',
    [baseThemeProps.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      letterSpacing: '-0.03em',
      lineHeight: 1.1,
    },
  },
  h5: {
    ...typographyMediumBase,
    fontSize: '3rem',
    letterSpacing: '-0.04em',
    lineHeight: 1.17,
  },
  h6: {
    ...typographyMediumBase,
    fontSize: '2.5rem',
    letterSpacing: '-0.05em',
    lineHeight: 1.1,
  },
  navigationItem: {
    ...typographyRegularBase,
    fontSize: '0.75rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.33,
    textTransform: 'capitalize',
  },
  paragraphBodyL: {
    lineHeight: 1.4,
  },
  paragraphBodyLBold: {
    lineHeight: 1.4,
  },
  paragraphBodyM: {
    lineHeight: 1.4,
  },
  paragraphBodyMBold: {
    lineHeight: 1.4,
  },
  paragraphBodyS: {
    fontSize: '0.875rem',
    lineHeight: 1.4,
  },
  paragraphBodySBold: {
    lineHeight: 1.4,
  },
};
